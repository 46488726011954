import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DialogWrapper from 'components/common/wrappers/DialogWrapper';

// image
import magnifiyingGlass from '../../../../images/magnifying-glass.svg';

// type
import { IDeleteCertificateErrorModalProps } from '../types';

const useStyles = makeStyles(() => {
    return {
        root: {
            '& .MuiDialog-paper': {
                height: '500px',
            },
        },
    };
});

const DeleteCertificateErrorModal: React.FC<IDeleteCertificateErrorModalProps> = ({
    Store,
    title,
    retryText,
    cancelText,
    onRetry,
    loading,
}) => {
    const classes = useStyles();
    return (
        <DialogWrapper
            open={Store.isOpen}
            onClose={Store.closeModal}
            className={'DeleteCertificateModal'}
            classes={{
                root: classes.root,
            }}
            hideDivider>
            <img className="magnifiyingGlass" src={magnifiyingGlass} />
            <h2 className="title">{title}</h2>
            <div className={'buttonContainer'}>
                <ButtonWrapper
                    variant="contained"
                    color="secondary"
                    className={'closeButton'}
                    fullWidth={false}
                    disabled={loading}
                    onClick={Store.closeModal}>
                    {cancelText}
                </ButtonWrapper>
                <ButtonWrapper
                    variant="contained"
                    color="primary"
                    className={'deleteButton'}
                    loading={loading}
                    disabled={loading}
                    fullWidth={false}
                    onClick={onRetry}>
                    {retryText}
                </ButtonWrapper>
            </div>
        </DialogWrapper>
    );
};

export default observer(DeleteCertificateErrorModal);
