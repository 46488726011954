import moment from 'moment';
import { NA } from 'constants/common';

export default function formatDate(dateValue: string) {
    if (!dateValue) {
        return '';
    }

    return moment(dateValue).format('MMMM Do YYYY');
}

export function formatDateAndTime(dateValue: string) {
    if (!dateValue) {
        return '';
    }

    return moment(dateValue).format('MMM D, YYYY HH:mm');
}

export function formatDateByType(
    dateValue: any,
    originalFormat: string,
    tobeFormat: string = 'YYYY-MM-DD',
) {
    if (!dateValue) {
        return '';
    }

    return moment(dateValue, originalFormat).format(tobeFormat);
}

export function getTime(dateValue: string) {
    if (!dateValue) {
        return 0;
    }

    return new Date(dateValue).getTime();
}

export function formatDateToString(date: any) {
    if (!date) {
        return '';
    }
    return moment.unix(date / 1000).toISOString();
}

export function formatDateToUTCFormat(date: any, toBeFormat: string = 'YYYY-MM-DD HH:mm') {
    date = moment(date).utc().format(toBeFormat);
    return date;
}

export function formatDateToUTC(date: string, toBeFormat: string = 'Do MMM YYYY-h:mm A'): string {
    if (!date) {
        return NA;
    }
    return moment(date).utc().format(toBeFormat);
}
