import { inject, observer, Provider } from 'mobx-react';
import React from 'react';

import CertificateManagementController from 'components/certificate-management/CertificateManagement.controller';
import DefaultContainer from 'layout/DefaultContainer';

import { ICreateCSRProps } from 'components/certificate-management/types';

const TabCertificates: React.FC<ICreateCSRProps> = ({ AppStore, CertificateManagementStore }) => {
    const { page } = CertificateManagementStore!;
    const { scope } = AppStore;
    return (
        <div className="tabCertificates">
            <Provider CertificateManagementStore={CertificateManagementStore}>
                <DefaultContainer>
                    {CertificateManagementController.getCertificateComponent(page, scope)}
                </DefaultContainer>
            </Provider>
        </div>
    );
};

export default inject('AppStore', 'CertificateManagementStore')(observer(TabCertificates));
