import { ADMIN, BNPP, TOKEN } from 'constants/banks';
import { PAYMENT_OPS } from 'constants/roles';

export default class UploadCertificateController {
    static async nextStep(AppStore, UploadCertificateStore, isCSRCreated, isRefund) {
        const scope = AppStore.scope;
        const userRole = AppStore.user?.role;
        if (isRefund) {
            await UploadCertificateStore.uploadFile(AppStore);
        }
        switch (scope) {
            case TOKEN:
                try {
                    await UploadCertificateStore.uploadCertificate(AppStore);
                } catch (e) {
                    // Already handled by uploadFile
                }
                break;
            case ADMIN:
                try {
                    await UploadCertificateStore.uploadCertificate(AppStore);
                } catch (e) {
                    // Already handled by uploadFile
                }
                break;
            default:
                if (isCSRCreated) {
                    await UploadCertificateStore.uploadFile(AppStore);
                } else if (scope === BNPP && userRole === PAYMENT_OPS) {
                    await UploadCertificateStore.uploadFile(AppStore);
                    break;
                } else if (scope === BNPP) {
                    await UploadCertificateStore.uploadCertificate(AppStore);
                    break;
                } else {
                    await UploadCertificateStore.setPayload(AppStore);
                }
                UploadCertificateStore.rootStore.nextStep();
                break;
        }
    }

    static getNextKey(scope, isCSRCreated) {
        switch (scope) {
            case TOKEN:
                return 'common:complete';
            case ADMIN:
                return 'common:complete';
            default:
                if (isCSRCreated) {
                    return 'common:complete';
                } else if (scope === BNPP) {
                    return 'common:complete';
                } else {
                    return 'common:next';
                }
        }
    }
}
