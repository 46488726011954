import { Tab, Tabs } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';

import AppController from 'base/App.controller';
import DefaultContainer from 'layout/DefaultContainer';

import { typedObjectKeys } from 'helpers/typed-object-keys/typed-object.keys';

import {
    ICertificateManagementTabs,
    ICreateCSRProps,
} from 'components/certificate-management/types';
import { Itranslation } from 'types';

const CertificateManagementMain: React.FC<ICreateCSRProps> = ({
    AppStore,
    CertificateManagementStore,
}) => {
    const { t }: Itranslation = AppController.getTranslation(
        AppStore.scope,
        'certificate-management',
    );
    const { allTabs, tabValue, handleTabChange } = CertificateManagementStore!;
    const ActiveTab = allTabs[tabValue as keyof ICertificateManagementTabs];

    return (
        <div className={'certificateManagementMain'}>
            <DefaultContainer>
                <h1>{t('title')}</h1>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    {typedObjectKeys(allTabs).map((key) => (
                        <Tab key={key} label={t(key)} value={key} />
                    ))}
                </Tabs>
                <ActiveTab />
            </DefaultContainer>
        </div>
    );
};

export default inject(
    'AppStore',
    'CertificateManagementStore',
)(observer(CertificateManagementMain));
