import { Grid, MenuItem } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';

import AppController from 'base/App.controller';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import FormWrapper from 'components/common/wrappers/FormWrapper';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import StepHandler from 'components/certificate-management/create-certificate/StepHandler';
import DefaultContainer from 'layout/DefaultContainer';

import { countries } from 'constants/countries';

import { ICreateCSRProps } from '../types';
import { ICountry, Itranslation } from 'types';

const useStyles = makeStyles(() => ({
    backButton: {
        color: 'var(--primary)',
        border: '1px solid var(--primary)',
        background: 'var(--ghost-white)',
        '&:hover': {
            border: '1px solid var(--primary)',
        },
        '& .MuiButton-label': {
            fontWeight: 'var(--font-weight-regular)',
        },
    },
}));

const CreateCSRStep5: React.FC<ICreateCSRProps> = ({ AppStore, CertificateManagementStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, [
        'create-certificate',
        'common',
    ]);
    const ref = React.createRef<HTMLFormElement>();
    const {
        rootStore,
        isDetailFormFilled,
        updateDetailField,
        companyDetailForm,
    } = CertificateManagementStore!.CreateCertificateStore;
    const classes = useStyles();

    const [ctx, setCtx] = React.useState({
        isFormValid: false,
    });

    useEffect(() => {
        const isFormValid = isDetailFormFilled && ref.current?.checkValidity();

        setCtx({ isFormValid: !!isFormValid });
    }, Object.values(companyDetailForm));

    const _handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateDetailField(event.target.name, event.target.value);
    };

    return (
        <div className={'createCSRStep5'}>
            <DefaultContainer>
                <Grid container>
                    <Grid container item xs={8}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <StepHandler />
                                </Grid>
                            </Grid>
                            <div className={'subtitle'}>{t('enterCompanyDetails')}</div>
                            <FormWrapper
                                className="form"
                                formRef={ref}
                                onSubmit={rootStore.nextStep}
                                isValid={ctx.isFormValid}>
                                <Grid container spacing={4} className="companyDetailForm">
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="organizationUnit"
                                            type="text"
                                            label={t('organizationUnit')}
                                            tooltipText={t('tooltips.organizationUnit')}
                                            value={companyDetailForm.organizationUnit}
                                            onChange={_handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SelectWrapper
                                            name="ncaCountry"
                                            label={t('ncaCountry')}
                                            value={companyDetailForm.ncaCountry}
                                            onChange={_handleChange}
                                            required>
                                            {countries.map((c: ICountry) => (
                                                <MenuItem key={c.code} value={c.code}>
                                                    {c.name}
                                                </MenuItem>
                                            ))}
                                        </SelectWrapper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="ncaLicenseNumber"
                                            type="text"
                                            label={t('ncaLicenseNumber')}
                                            tooltipText={t('tooltips.ncaLicenseNumber')}
                                            value={companyDetailForm.ncaLicenseNumber}
                                            onChange={_handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="companyNumber"
                                            type="text"
                                            label={t('companyNumber')}
                                            tooltipText={t('tooltips.companyNumber')}
                                            value={companyDetailForm.companyNumber}
                                            onChange={_handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="subjectAlternativeNames"
                                            type="text"
                                            label={t('subjectAlternativeNames')}
                                            value={companyDetailForm.subjectAlternativeNames}
                                            onChange={_handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </FormWrapper>
                        </Grid>
                        <Grid item xs={12} className={'bottomSection'}>
                            <Grid container spacing={4}>
                                <Grid item xs={2}>
                                    <ButtonWrapper
                                        startIcon={<ArrowBackIosIcon fontSize="small" />}
                                        variant="outlined"
                                        className={classes.backButton}
                                        onClick={rootStore.previousStep}>
                                        {t('common:back')}
                                    </ButtonWrapper>
                                </Grid>
                                <Grid item xs={8} />
                                <Grid item xs={2}>
                                    <ButtonWrapper
                                        className="saveButton"
                                        disabled={!ctx.isFormValid}
                                        onClick={rootStore.nextStep}>
                                        {t('common:next')}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCSRStep5));
