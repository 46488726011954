import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DialogWrapper from 'components/common/wrappers/DialogWrapper';

// image
import warning from '../../../../images/warning.svg';

// types
import { IDeleteCertificateModalProps } from '../types';

const useStyles = makeStyles(() => {
    return {
        root: {
            '& .MuiDialog-paper': {
                height: '500px',
            },
        },
    };
});

const DeleteCertificateModal: React.FC<IDeleteCertificateModalProps> = ({
    Store,
    title,
    submitText,
    cancelText,
    onSubmit,
    loading,
}) => {
    const classes = useStyles();

    return (
        <DialogWrapper
            open={Store.isOpen}
            onClose={Store.closeModal}
            className={'DeleteCertificateModal'}
            classes={{
                root: classes.root,
            }}
            hideDivider>
            <img className="warningImg" src={warning} />
            <h2 className="title">{title}</h2>
            <div className={'buttonContainer'}>
                <ButtonWrapper
                    variant="contained"
                    color="secondary"
                    className={'closeButton'}
                    name="closeDelete"
                    fullWidth={false}
                    disabled={loading}
                    onClick={Store.closeModal}>
                    {cancelText}
                </ButtonWrapper>
                <ButtonWrapper
                    variant="contained"
                    color="primary"
                    className={'deleteButton'}
                    name="confirmDelete"
                    loading={loading}
                    disabled={loading}
                    fullWidth={false}
                    onClick={onSubmit}>
                    {submitText}
                </ButtonWrapper>
            </div>
        </DialogWrapper>
    );
};

export default observer(DeleteCertificateModal);
