import { Grid, TableCell } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../base/App.controller';
import uploadIcon from '../../images/upload.svg';
import DefaultContainer from '../../layout/DefaultContainer';
import ButtonWrapper from '../common/wrappers/ButtonWrapper';
import CustomDivider from '../common/wrappers/CustomDivider';
import TableWrapper from '../common/wrappers/TableWrapper';

function CertificateManagementLandingType3({ AppStore, CertificateManagementStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, [
        'certificate-management',
        'common',
    ]);
    const { sendToUploadCert, loading, TableWrapperStore, isRefund } = CertificateManagementStore;

    useEffect(() => {
        CertificateManagementStore.fetchEidas(AppStore);
    }, []);

    const cells = (row, field) => {
        let value;
        switch (field) {
            case 'name':
            case 'expiration':
            case 'serialNumber':
                value = row[field];
                break;
            case 'type':
                value = t(`common:${row.type}`);
                break;
        }
        return (
            <TableCell key={field} align={'center'}>
                {value}
            </TableCell>
        );
    };

    const badge = (row) => {
        return (
            <TableCell key="badge" align={'center'}>
                <span className="badge">{row.status ? t('active') : t('inactive')}</span>
            </TableCell>
        );
    };

    const header = [t('name'), t('serialNumber'), t('type'), t('expiration'), t('status')];
    const cellTemplates = [
        (row) => cells(row, 'name'),
        (row) => cells(row, 'serialNumber'),
        (row) => cells(row, 'type'),
        (row) => cells(row, 'expiration'),
        badge,
    ];

    return (
        <div className={'certificateManagement'}>
            <DefaultContainer>
                {!isRefund && (
                    <>
                        <h1>{t('title')}</h1>
                        <CustomDivider />
                    </>
                )}
                <h2>{t('sub')}</h2>
                <Grid container justify="space-between" spacing={3}>
                    <Grid item xs={9} />
                    <Grid item xs={3}>
                        <ButtonWrapper
                            onClick={sendToUploadCert}
                            startIcon={<img src={uploadIcon} />}>
                            {t('uploadCertificate')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
                <TableWrapper
                    TableWrapperStore={TableWrapperStore}
                    header={header}
                    enablePagination={false}
                    disableRowSelect
                    cellTemplates={cellTemplates}
                    noDataMessage={t('youNeedCerts')}
                    loading={loading}
                />
            </DefaultContainer>
        </div>
    );
}

CertificateManagementLandingType3.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject(
    'AppStore',
    'CertificateManagementStore',
)(observer(CertificateManagementLandingType3));
