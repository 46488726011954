import { Divider, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import AppController from 'base/App.controller';
import DefaultContainer from 'layout/DefaultContainer';

import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';

// constants
import { TYPE_THREE } from 'constants/memberTypes';

function UploadCertificateComplete({ AppStore, CertificateManagementStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'upload-certificate');
    const isTypeThree = AppStore.member.memberType === TYPE_THREE;
    const { isRefund, isAdmin } = CertificateManagementStore;

    const { rootStore, reset } = CertificateManagementStore.UploadCertificateStore;

    const _onComplete = async () => {
        await rootStore.sendToLanding();
        reset();
    };

    const _renderDefault = () => {
        return (
            <Grid item xs={8} className={'bottomSection'}>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <ButtonWrapper className={'okayButton'} onClick={_onComplete}>
                            {t('common:okay')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <div className={'uploadCertComplete'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={_onComplete}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <Grid container item xs={12} spacing={4}>
                        {isTypeThree && !isAdmin && !isRefund && (
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        )}
                        <Grid item xs={8}>
                            <h2 className={'title'}>{t('thankYou')}</h2>
                        </Grid>
                        {_renderDefault()}
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
}

UploadCertificateComplete.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject(
    'AppStore',
    'CertificateManagementStore',
)(observer(UploadCertificateComplete));
