import { CircularProgress, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React from 'react';

import MemberSearchDropdownStore, { IOption } from './MemberSearchDropdownStore';

interface IMemberSearchDropdownProps {
    MemberSearchDropdownStore: MemberSearchDropdownStore;
}

const MemberSearchDropdown: React.FC<IMemberSearchDropdownProps> = ({
    MemberSearchDropdownStore,
}) => {
    const {
        cancelRequest,
        handleSearch,
        haveMore,
        loadingMembers,
        memberList,
        selectedMember,
        setMember,
    } = MemberSearchDropdownStore;

    const handleScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
        const listboxNode = event.currentTarget;

        const position = listboxNode.scrollTop + listboxNode.clientHeight;
        if (listboxNode.scrollHeight - position <= 1000 && haveMore && !loadingMembers) {
            handleSearch(true);
        }
    };

    return (
        <div className={`memberSearchBar`}>
            <Grid container>
                <Grid item xs={12}>
                    <Autocomplete
                        id="member-search"
                        onOpen={() => {
                            if (!memberList.length) {
                                handleSearch(false);
                            }
                        }}
                        onClose={cancelRequest}
                        options={memberList}
                        disableClearable
                        value={selectedMember}
                        getOptionLabel={(option) =>
                            option.id ? `${option.id} - ${option.title}` : ''
                        }
                        onChange={(_, value) => setMember(value as IOption)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Member ID"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loadingMembers ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                        ListboxProps={{
                            onScroll: handleScroll,
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default observer(MemberSearchDropdown);
