import React from 'react';
import { ADMIN, TOKEN } from '../../constants/banks';
import { RESELLER, UNDER_RESELLER } from '../../constants/clientType';
import CertificateManagementLanding from './CertificateManagementLanding';
import CertificateManagementLandingType3 from './CertificateManagementLanding.type3';
import CertificateManagementMain from 'components/certificate-management/CertificateManagementMain';
import CreateCSRStep1 from './create-certificate/CreateCSRStep1';
import CreateCSRStep2 from './create-certificate/CreateCSRStep2';
import CreateCSRStep3 from './create-certificate/CreateCSRStep3';
import CreateCSRStep4 from './create-certificate/CreateCSRStep4';
import CreateCSRStep5 from './create-certificate/CreateCSRStep5';
import CreateCSRStep6 from './create-certificate/CreateCSRStep6';
import CertificateLanding from 'components/certificate-management/certificates/CertificateLanding';
import TabCertificates from 'components/certificate-management/certificates/TabCertificates';
import TabCreateCSR from 'components/certificate-management/create-certificate/TabCreateCSR';
import UploadCertificate from './upload-certificate/UploadCertificate';
import UploadCertificateComplete from './upload-certificate/UploadCertificateComplete';
import UploadCertificateVerifyType3 from './upload-certificate/UploadCertificateVerify.type3';
import BankRegistration from '../bank-registration/BankRegistration';
import BankConfigurationMain from '../bank-configuration/BankConfigurationMain';
import Register from 'components/bank-registration/register/Register';

const COMPONENTS = {
    CERTIFICATE_1: <CertificateLanding />,
    UPLOAD_1: <UploadCertificate />,
    UPLOAD_2: <UploadCertificateVerifyType3 />,
    UPLOAD_3: <UploadCertificateComplete />,
    CREATE_1: <CreateCSRStep1 />,
    CREATE_2: <CreateCSRStep2 />,
    CREATE_3: <CreateCSRStep3 />,
    CREATE_4: <CreateCSRStep4 />,
    CREATE_5: <CreateCSRStep5 />,
    CREATE_6: <CreateCSRStep6 />,
    BANK_REGISTRATION_1: <BankRegistration />,
    BANK_REGISTRATION_2: <Register />,
    BANK_CONFIGURATION_1: <BankConfigurationMain />,
};

export default class CertificateManagementController {
    static getComponent(page, scope, clientType) {
        switch (scope) {
            case TOKEN:
                return <CertificateManagementMain />;
            case ADMIN:
                return <CertificateManagementMain />;
            default:
                if (clientType === RESELLER || clientType === UNDER_RESELLER)
                    return <CertificateManagementMain />;
                else if (page.type) return COMPONENTS[`${page.type}_${page.step}`];
                else return <CertificateManagementLandingType3 />;
        }
    }

    static getCertificateComponent(page, scope, isRefund = false) {
        if (page.type) {
            return COMPONENTS[`${page.type}_${page.step}`];
        } else if (isRefund) {
            return <CertificateManagementLanding />;
        } else {
            return <CertificateLanding />;
        }
    }

    static setPath(CertificateManagementStore, subpath, scope) {
        switch (subpath) {
            case 'CREATE':
                switch (scope) {
                    case TOKEN:
                        CertificateManagementStore.handleTabChange(null, 'createCSR');
                        break;
                    default:
                        CertificateManagementStore.sendToLanding();
                        break;
                }
                break;
            case 'UPLOAD':
                CertificateManagementStore.sendToUploadCert();
                break;
            default:
                CertificateManagementStore.sendToLanding();
                break;
        }
    }

    static getTabs(isAdmin) {
        const tabs = {
            certificates: TabCertificates,
            createCSR: TabCreateCSR,
        };
        if (isAdmin) {
            delete tabs.createCSR;
        }
        return tabs;
    }
}
