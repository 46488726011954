import {
    FormControlLabel,
    Grid,
    Input,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';

import AppController from 'base/App.controller';
import DefaultContainer from 'layout/DefaultContainer';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import StepHandler from 'components/certificate-management/create-certificate/StepHandler';

import { NO, YES } from 'constants/configAvailable';

import { Itranslation } from 'types';
import { ICreateCSRProps } from 'components/certificate-management/types';

const StyledTextField = withStyles({
    root: {
        height: '45px',
    },
})(Input);

const useStyles = makeStyles(() => ({
    backButton: {
        color: 'var(--primary)',
        border: '1px solid var(--primary)',
        background: 'var(--ghost-white)',
        '&:hover': {
            border: '1px solid var(--primary)',
        },
        '& .MuiButton-label': {
            fontWeight: 'var(--font-weight-regular)',
        },
    },
}));

const CreateCSRStep3: React.FC<ICreateCSRProps> = ({ AppStore, CertificateManagementStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, [
        'create-certificate',
        'common',
    ]);
    const classes = useStyles();
    const {
        dn,
        loading,
        hasCustomDN,
        setHasCustomDN,
        updateDn,
        rootStore,
    } = CertificateManagementStore!.CreateCertificateStore;

    const _handleClick = () => {
        if (hasCustomDN === YES) rootStore.jumpToStep(6);
        else {
            updateDn('');
            rootStore.nextStep();
        }
    };
    const isDisabled = hasCustomDN === YES && !dn;

    return (
        <div className={'ceateCSRStep3'}>
            <DefaultContainer>
                <Grid container>
                    <Grid container item xs={8}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <StepHandler />
                                </Grid>
                                <Grid item xs={8}>
                                    <div className={'subtitle'}>{t('step3Subtitle')}</div>
                                    <RadioGroup
                                        row
                                        aria-label="customDN"
                                        name="customDN"
                                        value={hasCustomDN}
                                        onChange={(e) => setHasCustomDN(e.target.value)}
                                        className={'radioGroup'}>
                                        <FormControlLabel
                                            value={YES}
                                            control={<Radio />}
                                            label={t('yesDN')}
                                        />
                                        <FormControlLabel
                                            value={NO}
                                            control={<Radio />}
                                            label={t('noDN')}
                                        />
                                    </RadioGroup>
                                    <Grid container spacing={4} className="field">
                                        <Grid item xs={12}>
                                            <Tooltip title={t('tooltips.dn')!} arrow>
                                                <StyledTextField
                                                    name="dn"
                                                    value={dn}
                                                    onChange={(e) => updateDn(e.target.value)}
                                                    required
                                                    {...(hasCustomDN === NO && {
                                                        disabled: true,
                                                        value: '',
                                                    })}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography className="infoText">
                                        {t('step3Description')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} className={'bottomSection'}>
                                <Grid container spacing={4}>
                                    <Grid item xs={3}>
                                        <ButtonWrapper
                                            startIcon={<ArrowBackIosIcon fontSize="small" />}
                                            variant="outlined"
                                            loading={loading}
                                            className={classes.backButton}
                                            onClick={rootStore.previousStep}>
                                            {t('common:back')}
                                        </ButtonWrapper>
                                    </Grid>
                                    <Grid item xs={6} />
                                    <Grid item xs={3}>
                                        <ButtonWrapper
                                            className="noButton"
                                            onClick={_handleClick}
                                            disabled={isDisabled}>
                                            {t('common:next')}
                                        </ButtonWrapper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCSRStep3));
